import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.scss";
import DevisForm from "../components/DevisForm";
import { useImage } from "../components/AsyncImage";
import {sendWcbEvent} from "../functions";

const HeaderImage = ({ file }) => {

    const { hasLoaded, hasError } = useImage(file?.childImageSharp?.fluid.src);

    let imageClass = '';
    let style = {};

    if (hasError) {
        return null;
    }

    if(hasLoaded){
        imageClass = 'loaded';
        style = {
            backgroundImage: `url(${file?.childImageSharp?.fluid.src})`
        }
    }


    return(
        <div className={ 'image-wallpaper ' + imageClass} style={ style } />
    )
}

const HomepageTemplate = ({data: { wpPage, file }}) => {
    return (
        <Layout isHomePage={ wpPage.isFrontPage }>
            <SEO title={ wpPage.title } description={ wpPage.content } post={ wpPage }/>
            <div className="homepage">
                <section className="section first-block">
                    <HeaderImage file={ file }/>
                    <div className="inner">
                        <h1>
                            <span className="yellow-words">Dites stop aux nuisibles,</span>
                            <span>appelez-nous, nous les détruisons pour vous !</span>
                        </h1>
                        <a href="tel:+33695434091" className="call-to-action toTel" onClick={() => sendWcbEvent()}>06 95 43 40 91</a>
                        <span className="local">*Prix d'un appel local</span>
                        <span className="rappel">Rappel gratuit et immédiat</span>
                    </div>
                </section>
                <section className="section second-block">
                    <div className="inner">
                        <div className="block-left">
                            <h1>
                                <span className="yellow-words">Dites-nous en un peu plus </span>
                                sur votre problème !
                            </h1>
                            <p>Nous nous engageons à vous répondre dans les 48h après votre envoi. Si votre problème nécessite une intervention rapide, n'hésitez pas à nous appeler.</p>
                        </div>
                        <div className="form">
                            <DevisForm />
                        </div>
                    </div>
                </section>
                <section className="section third-block">
                    <div className="inner">
                        <div className="items" />
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default HomepageTemplate;

export const pageQuery = graphql`
  query getHomepage {
      file(relativePath: { eq: "wallpaper-home.png" }) {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 90, toFormat: JPG) {
              src
            }
          }
      }
      wpPage(isFrontPage: {in: true}) {
        id
        uri
        title
        content
        isFrontPage
        slug
        seo {
            canonical
            title
            metaDesc
            focuskw
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphAuthor
            opengraphDescription
            opengraphTitle
            opengraphDescription
            opengraphImage {
                altText
                sourceUrl
                srcSet
            }
            opengraphUrl
            opengraphSiteName
            opengraphPublishedTime
            opengraphModifiedTime
            twitterTitle
            twitterDescription
            twitterImage {
                altText
                sourceUrl
                srcSet
            }
            breadcrumbs {
                url
                text
            }
            cornerstone
            schema {
                pageType
                articleType
                raw
            }
            readingTime
       }
        acfHomepage {
          texte
          fieldGroupName
        }
      }
  }
`

